require('array.prototype.find').shim(); // Fuck Internet Explorer

import $ from 'jquery'
import 'jquery-mask-plugin'
import 'whatwg-fetch'

window.jQuery = $
window.$ = window.jQuery

import * as bootstrap from 'bootstrap'
window.bootstrap = bootstrap;

import 'bootstrap-datepicker'
import 'bootstrap-notify'
import 'datatables.net'
import 'datatables.net-bs5'
import 'datatables.net-dt'
import 'datatables.net-buttons-dt'
import 'datatables.net-buttons/js/buttons.colVis.min'
import 'datatables.net-buttons/js/buttons.html5.js'

import Dropzone from 'dropzone'
window.Dropzone = Dropzone;

require('select2/dist/js/select2.full.min')

import * as moment_tz from 'moment-timezone'
window.moment_tz = moment_tz
let moment = require('/node_modules/moment-business-days/index')
window.moment = moment

import 'jquery-ui/ui/core'
import 'jquery-ui/ui/widget'
import 'jquery-ui/ui/widgets/dialog'
import 'jquery-ui/ui/widgets/autocomplete'
import 'jquery-ui/ui/widgets/menu'

require("/node_modules/@yaireo/tagify/dist/tagify.polyfills.min.js"); //gross
import Tagify from '@yaireo/tagify'
window.Tagify = Tagify

window.pdfjs = require("/node_modules/pdfjs-dist/es5/build/pdf");
window.pdfjs.GlobalWorkerOptions.workerSrc = "./js/pdf.worker.js";

var Twig = require('twig');
Twig.extendFilter('currency', function(value) {
    return app.format.money(value);
});


Twig.extendFilter('phone', function(value) {
    var cleaned = ('' + value).replace(/\D/g, '')
    var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return value;
});
window.twig = Twig.twig;

import { v4 as uuidv4 } from 'uuid';
window.uuidv4 = uuidv4;

// Tabs
$('a[data-bs-toggle="tab"]').on('shown.bs.tab', function() {
    $(this).parents('.nav-tabs').find('.active').removeClass('active');
    $(this).parents('.nav-pills').find('.active').removeClass('active');
    $(this).addClass('active').parent().addClass('active');
});

// Facebook
window.fbAsyncInit = function() {
    FB.init({
        appId            : '554472048282421',
        autoLogAppEvents : true,
        xfbml            : true,
        version          : 'v3.2'
    });
};
